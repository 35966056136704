.mat-form-field-appearance-outline .mat-form-field-flex {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.mat-form-field-flex > .mat-form-field-infix {
  padding: 0.5em 0 !important;
}

.mat-form-field-label-wrapper {
  top: -1.5em !important;
}

.mat-form-field-wrapper {
  padding-bottom: 1.1em !important;
}

.mat-form-field-has-label.mat-form-field-can-float.mat-form-field-should-float {
  .mat-form-field-label-wrapper {
    .mat-form-field-label {
      transform: translateY(-1.3em) scale(0.75) !important;
      width: 133.33333% !important;
    }
  }
}
