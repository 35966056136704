@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .st-timeline-dot-variant-outlined {
    box-shadow: none;
    background-color: transparent;
    &.st-timeline-dot-color-error {
      border-color: mat.get-color-from-palette(mat.$red-palette, 500);
    }
    &.st-timeline-dot-color-grey {
      border-color: mat.get-color-from-palette(mat.$grey-palette, 500);
    }
    &.st-timeline-dot-color-info {
      border-color: mat.get-color-from-palette(mat.$indigo-palette, 500);
    }
    &.st-timeline-dot-color-inherit {
      border-color: inherit;
    }
    &.st-timeline-dot-color-primary {
      border-color: mat.get-color-from-palette($primary-palette, 500);
    }
    &.st-timeline-dot-color-success {
      border-color: mat.get-color-from-palette(mat.$green-palette, 500);
    }
    &.st-timeline-dot-color-warning {
      border-color: mat.get-color-from-palette(mat.$amber-palette, 500);
    }
  }

  .st-timeline-dot-variant-filled {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-color: transparent;
    &.st-timeline-dot-color-error {
      background-color: mat.get-color-from-palette(mat.$red-palette, 500);
      color: #fff;
    }
    &.st-timeline-dot-color-grey {
      background-color: mat.get-color-from-palette(mat.$grey-palette, 500);
      color: #fff;
    }
    &.st-timeline-dot-color-info {
      background-color: mat.get-color-from-palette(mat.$indigo-palette, 500);
      color: #fff;
    }
    &.st-timeline-dot-color-inherit {
      background-color: inherit;
      color: currentColor;
    }
    &.st-timeline-dot-color-primary {
      background-color: mat.get-color-from-palette($primary-palette, 500);
      color: #fff;
    }
    &.st-timeline-dot-color-success {
      background-color: mat.get-color-from-palette(mat.$green-palette, 500);
      color: #fff;
    }
    &.st-timeline-dot-color-warning {
      background-color: mat.get-color-from-palette(mat.$amber-palette, 500);
      color: #fff;
    }
  }

  .st-timeline-connector {
    background-color: mat.get-color-from-palette(mat.$grey-palette, 500);
  }

  .st-timeline-connector-color-error {
    background-color: mat.get-color-from-palette(mat.$red-palette, 500);
  }
  .st-timeline-connector-color-grey {
    background-color: mat.get-color-from-palette(mat.$grey-palette, 500);
  }
  .st-timeline-connector-color-info {
    background-color: mat.get-color-from-palette(mat.$indigo-palette, 500);
  }
  .st-timeline-connector-color-inherit {
    background-color: inherit;
  }
  .st-timeline-connector-color-primary {
    background-color: mat.get-color-from-palette($primary-palette, 500);
  }
  .st-timeline-connector-color-success {
    background-color: mat.get-color-from-palette(mat.$green-palette, 500);
  }
  .st-timeline-connector-color-warning {
    background-color: mat.get-color-from-palette(mat.$amber-palette, 500);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
