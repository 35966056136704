.st-button-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  button {
    border-radius: 0;
  }

  > button {
    position: relative;
    flex: 1 1 auto;
  }

  > button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  button:nth-child(2) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 0;
    min-width: 32px;
  }
}
