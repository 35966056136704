@use 'sass:map';
@use '@angular/material' as mat;

/* Include the core Angular Material styles */
@include mat.core();

$typography: mat.define-typography-config(
  $font-family: '-apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji"',
  $title: mat.define-typography-level(1.25rem, 2rem, 600),
  $body-2: mat.define-typography-level(0.875rem, 1.5rem, 600),
  $button: mat.define-typography-level(0.875rem, 0.875rem, 500),
  $input: mat.define-typography-level(0.875rem, 1.2857142857, 400),
);

/* Tema base sem cor. Densidade e tipografia são iseridos nesta etapa para toda a aplicação */
@include mat.all-component-themes(
  (
    color: null,
    density: 0,
    typography: $typography,
  )
);

$st-primary: mat.define-palette(mat.$orange-palette);
$st-accent: mat.define-palette(mat.$green-palette);
$st-warn: mat.define-palette(mat.$red-palette);

body .st-light-theme {
  $light-theme: mat.define-light-theme(
    (
      color: (
        primary: $st-primary,
        accent: $st-accent,
        warn: $st-warn,
      ),
    )
  );

  /* Use all-component-colors to only generate the colors */
  @include mat.all-component-colors($light-theme);
  @include custom-components-theme($light-theme);
}

body .st-dark-theme {
  $dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $st-primary,
        accent: $st-accent,
        warn: $st-warn,
      ),
    )
  );

  /* Use all-component-colors to only generate the colors */
  @include mat.all-component-colors($dark-theme);
  @include custom-components-theme($dark-theme);
}
