@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark: map.get($color-config, 'is-dark');
  $primary: map.get($color-config, 'primary');
  $border-color-dark: mat.get-color-from-palette(mat.$grey-palette, 800);
  $border-color-light: mat.get-color-from-palette(mat.$grey-palette, 200);

  st-plano {
    .plano-container {
      @if $is-dark {
        border: 1px solid $border-color-dark;
      } @else {
        border: 1px solid $border-color-light;
      }
    }

    .plano-header {
      background-color: #{map.get(map.get($color-config, 'background'), 'hover')};
    }

    .adicionais-container {
      @if $is-dark {
        border: 1px solid $border-color-dark;
      } @else {
        border: 1px solid $border-color-light;
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
