@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary: map.get($color-config, 'primary');
  $is-dark: map.get($color-config, 'is-dark');
  $foreground: map.get($color-config, 'foreground');
  $foreground-secondary-text: #{map.get($foreground, 'secondary-text')};

  st-ctes-list {
    .row-focused {
      border-left: 0.25rem solid mat.get-color-from-palette($primary);
    }

    .row-selected {
      @if $is-dark {
        background-color: mat.get-color-from-palette(mat.$grey-palette, 800);
      } @else {
        background-color: mat.get-color-from-palette(mat.$grey-palette, 200);
      }
    }

    .table .mat-header-row {
      @if $is-dark {
        background-color: mat.get-color-from-palette(mat.$grey-palette, 800);
      } @else {
        background-color: mat.get-color-from-palette(mat.$grey-palette, 100);
      }
      color: $foreground-secondary-text;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
