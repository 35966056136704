.st-table {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: table;
  border-collapse: collapse;

  .mat-header-cell {
    font-weight: bold;
  }

  .mat-header-cell,
  .mat-header-row {
    margin: 0 auto;
    vertical-align: middle;
    align-self: center;
    max-width: 100%;
  }

  .mat-cell,
  .mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 10px;
    line-break: unset;
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
    height: 38px;
    min-height: 30px !important;
  }
}
