/* importação dos componentes personalizados */
@use './../../app/shared/components/timeline/timeline-theme' as timeline;
@use './../../app/core/layout/sidenav/components/sidenav-item/sidenav-item-theme' as sidenavItem;
@use './../../app/modules/settings/settings-theme' as settings;
@use './../../app/modules/plano/pages/plano-theme' as plano;
@use './../../app/modules/geral-config/geral-config-theme' as geralConfig;
@use './../../app/modules/parceiros/pages/parceiros-list/parceiros-list-theme' as parceirosList;
@use './../../app/modules/veiculos/pages/veiculos-list/veiculos-list-theme' as veiculosList;
@use './../../app/modules/ctes/pages/ctes-list/ctes-list-theme' as ctesList;
@use './../../app/modules/ctes/components/import-xml-dialog/import-xml-dialog-theme' as importXml;
@use './../../app/modules/mdfes/pages/mdfes-list/mdfes-list-theme' as mdfesList;
@use './../../app/modules/mdfes/components/documentos-list-dialog/documentos-list-dialog-theme' as mdfesDocumentosList;
@use './../../app/modules/agencias/pages/agencias-list/agencias-list-theme' as agenciasList;
@use './../../app/modules/cfops/pages/cfops-list/cfops-list-theme' as cfopsList;
@use './../../app/modules/series/pages/series-list/series-list-theme' as seriesList;
@use './../../app/modules/unidades/pages/unidades-list/unidades-list-theme' as unidadesList;
@use './../../app/modules/grupo-usuario/pages/grupo-usuario-list/grupo-usuario-list-theme' as grupoUsuarioList;
@use './../../app/modules/usuarios/pages/usuarios-list/usuarios-list-theme' as usuariosList;

@import '../utils/utilities-theme.scss';

@mixin custom-components-theme($theme) {
  /* utilizar include para componentes personalizados
  onde há necessidade de mudança no css com as
  váriaveis primary, accent e warn */
  @include st-utilities-theme($theme);
  @include timeline.theme($theme);
  @include sidenavItem.theme($theme);
  @include parceirosList.theme($theme);
  @include veiculosList.theme($theme);
  @include ctesList.theme($theme);
  @include importXml.theme($theme);
  @include mdfesList.theme($theme);
  @include mdfesDocumentosList.theme($theme);
  @include settings.theme($theme);
  @include plano.theme($theme);
  @include agenciasList.theme($theme);
  @include cfopsList.theme($theme);
  @include seriesList.theme($theme);
  @include unidadesList.theme($theme);
  @include geralConfig.theme($theme);
  @include grupoUsuarioList.theme($theme);
  @include usuariosList.theme($theme);
}
