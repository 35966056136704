@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary: map.get($color-config, 'primary');

  st-import-xml-dialog {
    .card-documento:hover {
      border: 1px solid mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($primary);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
