@use 'sass:map';
@use '@angular/material' as mat;

@mixin st-utilities-theme($theme) {
  $color-config: mat.get-color-config($theme);

  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');
  $warn: map.get($color-config, 'warn');
  $is-dark: map.get($color-config, 'is-dark');

  $foreground: map.get($color-config, 'foreground');
  $foreground-base: #{map.get($foreground, 'base')};
  $foreground-divider: #{map.get($foreground, 'divider')};
  $foreground-dividers: #{map.get($foreground, 'dividers')};
  $foreground-disabled: #{map.get($foreground, 'disabled')};
  $foreground-disabled-button: #{map.get($foreground, 'disabled-button')};
  $foreground-disabled-text: #{map.get($foreground, 'disabled-text')};
  $foreground-elevation: #{map.get($foreground, 'elevation')};
  $foreground-hint-text: #{map.get($foreground, 'hint-text')};
  $foreground-secondary-text: #{map.get($foreground, 'secondary-text')};
  $foreground-icon: #{map.get($foreground, 'icon')};
  $foreground-icons: #{map.get($foreground, 'icons')};
  $foreground-mat-icon: #{map.get($foreground, 'mat-icon')};
  $foreground-text: #{map.get($foreground, 'text')};
  $foreground-slider-min: #{map.get($foreground, 'slider-min')};
  $foreground-slider-off: #{map.get($foreground, 'slider-off')};
  $foreground-slider-off-active: #{map.get($foreground, 'slider-off-active')};

  $background: map.get($color-config, 'background');
  $background-status-bar: #{map.get($background, 'status-bar')};
  $background-app-bar: #{map.get($background, 'app-bar')};
  $background-background: #{map.get($background, 'background')};
  $background-hover: #{map.get($background, 'hover')};
  $background-card: #{map.get($background, 'card')};
  $background-dialog: #{map.get($background, 'dialog')};
  $background-disabled-button: #{map.get($background, 'disabled-button')};
  $background-raised-button: #{map.get($background, 'raised-button')};
  $background-focused-button: #{map.get($background, 'focused-button')};
  $background-selected-button: #{map.get($background, 'selected-button')};
  $background-selected-disabled-button: #{map.get($background, 'selected-disabled-button')};
  $background-disabled-button-toggle: #{map.get($background, 'disabled-button-toggle')};
  $background-unselected-chip: #{map.get($background, 'unselected-chip')};
  $background-disabled-list-option: #{map.get($background, 'disabled-list-option')};
  $background-tooltip: #{map.get($background, 'tooltip')};

  .st-permission-denied {
    cursor: not-allowed !important;
    background-color: $background-disabled-button !important;
    color: $foreground-disabled-text !important;
  }

  .st-bg-base {
    background: $background-background;
  }

  /* cor fonte */
  .st-text-color-base {
    color: $foreground-base;
  }

  .st-text-color-secondary-text {
    color: $foreground-secondary-text;
  }

  .st-bg-hover:hover {
    background-color: $background-hover;
  }

  .apexcharts-menu {
    color: $foreground-text;
    background: $background-background;
  }

  .apexcharts-menu-item:hover {
    background: $background-hover;
  }

  /* Cor de background e fonte padronizado */
  .default-template-color {
    color: mat.get-color-from-palette(mat.$grey-palette, A100) !important;
    background-color: mat.get-color-from-palette($default) !important;
  }

  .info-template-color {
    color: mat.get-color-from-palette(mat.$grey-palette, A100) !important;
    background-color: mat.get-color-from-palette($info) !important;
  }

  .warning-template-color {
    color: mat.get-color-from-palette(mat.$grey-palette, A100) !important;
    background-color: mat.get-color-from-palette($warning) !important;
  }

  .primary-template-color {
    color: mat.get-color-from-palette(mat.$grey-palette, A100) !important;
    background-color: mat.get-color-from-palette($primary) !important;
  }

  .accent-template-color {
    color: mat.get-color-from-palette(mat.$grey-palette, A100) !important;
    background-color: mat.get-color-from-palette($accent) !important;
  }

  .error-template-color {
    color: mat.get-color-from-palette(mat.$grey-palette, A100) !important;
    background-color: mat.get-color-from-palette($error) !important;
  }

  .success-template-color {
    color: mat.get-color-from-palette(mat.$grey-palette, A100);
    background-color: mat.get-color-from-palette($success);
  }

  /* template colors hover */

  .error-template-color--hover {
    &:hover {
      background-color: mat.get-color-from-palette($error) !important;
      color: mat.get-color-from-palette($light);

      .mat-icon {
        color: mat.get-color-from-palette($light);
      }
    }
  }

  /* Autocomplete change colors */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    -webkit-text-fill-color: mat.get-color-from-palette($foreground, base);
  }

  /* Color background */
  .st-bg-primary {
    background-color: mat.get-color-from-palette($primary);
  }

  .st-bg-primary-light {
    background-color: rgba($color: mat.get-color-from-palette($primary), $alpha: 0.1);
  }

  .st-bg-accent {
    background-color: mat.get-color-from-palette($accent);
  }

  .st-bg-warn {
    background-color: mat.get-color-from-palette($warn);
  }

  .st-bg-success {
    background-color: mat.get-color-from-palette($success);
  }

  .st-bg-info {
    background-color: mat.get-color-from-palette($info);
  }

  .st-bg-default {
    background-color: mat.get-color-from-palette($default);
  }

  .st-bg-warning {
    background-color: mat.get-color-from-palette($warning);
  }

  .st-bg-error {
    background-color: mat.get-color-from-palette($error);
  }

  .st-bg-light {
    background-color: mat.get-color-from-palette($light);
  }

  .st-bg-dark {
    background-color: mat.get-color-from-palette($dark);
  }

  /* Color fonte */
  .st-color-primary {
    color: mat.get-color-from-palette($primary);
  }

  .st-color-accent {
    color: mat.get-color-from-palette($accent);
  }

  .st-color-warn {
    color: mat.get-color-from-palette($warn);
  }

  .st-color-success {
    color: mat.get-color-from-palette($success);
  }

  .st-color-info {
    color: mat.get-color-from-palette($info);
  }

  .st-color-default {
    color: mat.get-color-from-palette($default);
  }

  .st-color-warning {
    color: mat.get-color-from-palette($warning);
  }

  .st-color-error {
    color: mat.get-color-from-palette($error);
  }

  .st-color-light {
    color: mat.get-color-from-palette($light);
  }

  .st-color-dark {
    color: mat.get-color-from-palette($dark);
  }

  /* Hover color */
  .st-hover-primary:hover {
    color: mat.get-color-from-palette($primary);
  }

  /* Active colors */
  .st-active-primary {
    color: mat.get-color-from-palette($primary);
  }

  /* Color border */
  .st-border-transparent {
    border-color: transparent;
  }

  .st-border-primary {
    border-color: mat.get-color-from-palette($primary);
  }

  .st-border-accent {
    border-color: mat.get-color-from-palette($accent);
  }

  .st-border-warn {
    border-color: mat.get-color-from-palette($warn);
  }

  .st-border-warning {
    border-color: mat.get-color-from-palette($warning);
  }

  .st-border-default {
    border-color: mat.get-color-from-palette($default);
  }

  .bg-pattern {
    background: linear-gradient(
        135deg,
        $background-background 22px,
        $background-hover 22px,
        $background-hover 24px,
        transparent 24px,
        transparent 67px,
        $background-hover 67px,
        $background-hover 69px,
        transparent 69px
      ),
      linear-gradient(
          225deg,
          $background-background 22px,
          $background-hover 22px,
          $background-hover 24px,
          transparent 24px,
          transparent 67px,
          $background-hover 67px,
          $background-hover 69px,
          transparent 69px
        )
        0 64px;
    background-color: $background-background;
    background-size: 64px 128px;
  }
}
