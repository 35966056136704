/* Colors */
@use '@angular/material' as mat;

$success: mat.define-palette(mat.$green-palette, 500) !default;
$info: mat.define-palette(mat.$blue-grey-palette, 500) !default;
$default: mat.define-palette(mat.$grey-palette, 500) !default;
$warning: mat.define-palette(mat.$amber-palette, 500) !default;
$error: mat.define-palette(mat.$red-palette, 500) !default;
$light: mat.define-palette(mat.$grey-palette, A100) !default;
$dark: mat.define-palette(mat.$grey-palette, 900) !default;

/* Background-base */
$st-background-base: #121212 !default;

$font-size-base: 1rem !default;

/* Headings */
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

:root {
  // Transições
  --trans-ease-out: all var(--trans-ease-out-duration) var(--trans-ease-out-timing-function);
  --trans-ease-out-duration: #{$swift-ease-out-duration};
  --trans-ease-out-timing-function: #{$swift-ease-out-timing-function};

  /* sidenav itens */
  --sidenav-item-padding: 1.5rem;
  --sidenav-item-icon-gap: 16px;
  --sidenav-item-icon-size: 24px;
  --sidenav-item-dropdown-gap: 12px;
}
