.st-modal {
  @media screen and (max-width: 599px) {
    max-width: 100vw !important;
  }

  .mat-dialog-container {
    padding: 0;
    border-radius: 16px;
  }

  .st-dialog-header {
    padding: 0 1rem;
    width: 100%;
    min-height: 4rem;
  }

  .mat-dialog-content {
    padding: 0.5rem;
    margin: 0;
  }

  .mat-dialog-actions {
    margin: 0;
    padding: 0.5rem;
    min-height: auto;
  }
}
