.st-paginator {
  background-color: transparent;
  margin-left: 0;
  padding: 0;

  .mat-paginator-container {
    min-height: auto;
  }

  .mat-paginator-range-label {
    margin: 0 15px !important;
    font-size: 0.8rem;
  }
}
