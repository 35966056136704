@charset 'utf-8';

/* Angular import */
@import '@angular/material/theming';

/* scss inicial */
@import './abstracts/variables';
@import './abstracts/functions';
@import './abstracts/mixins';

/* Themes */
@import './themes.scss';

/* configuracao inicial */
@import './base/normalize';
@import './base/types';

/* Componentes */
@import './components/backdrop';
@import './components/buttons';
@import './components/modal';
@import './components/table';
@import './components/list-table';
@import './components/menu-list';
@import './components/paginator';
@import './components/form-field';
@import './components/loading';
@import './components/scrollbar';
@import './components/icons';
@import './components/toolbar';

/* Utils */
@import './utils/utilities';
@import './utils/utilities-theme';
