.st-d-inline {
  display: inline !important;
}

.st-d-inline-block {
  display: inline-block !important;
}

.st-d-block {
  display: block !important;
}

.st-d-grid {
  display: grid !important;
}

.st-d-table {
  display: table !important;
}

.st-d-table-row {
  display: table-row !important;
}

.st-d-table-cell {
  display: table-cell !important;
}

.st-d-flex {
  display: flex !important;
}

.st-d-inline-flex {
  display: inline-flex !important;
}

.st-d-none {
  display: none !important;
}

.st-w-25 {
  width: 25% !important;
}

.st-w-50 {
  width: 50% !important;
}

.st-w-75 {
  width: 75% !important;
}

.st-w-100 {
  width: 100% !important;
}

.st-w-auto {
  width: auto !important;
}

.st-mw-25 {
  max-width: 25% !important;
}

.st-mw-50 {
  max-width: 50% !important;
}

.st-mw-75 {
  max-width: 75% !important;
}

.st-mw-100 {
  max-width: 100% !important;
}

.st-max-vw-75 {
  max-width: 75vw !important;
}

.st-vw-100 {
  width: 100vw !important;
}

.st-min-vw-100 {
  min-width: 100vw !important;
}

.st-h-25 {
  height: 25% !important;
}

.st-h-50 {
  height: 50% !important;
}

.st-h-75 {
  height: 75% !important;
}

.st-h-100 {
  height: 100% !important;
}

.st-h-auto {
  height: auto !important;
}

.st-maxh-25 {
  max-height: 25% !important;
}

.st-maxh-50 {
  max-height: 50% !important;
}

.st-maxh-75 {
  max-height: 75% !important;
}

.st-maxh-100 {
  max-height: 100% !important;
}

.st-minh-100 {
  min-height: 100% !important;
}

.st-vh-100 {
  height: 100vh !important;
}

.st-min-vh-100 {
  min-height: 100vh !important;
}

.st-flex-fill {
  flex: 1 1 auto !important;
}

.st-flex-row {
  flex-direction: row !important;
}

.st-flex-column {
  flex-direction: column !important;
}

.st-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.st-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.st-flex-grow-0 {
  flex-grow: 0 !important;
}

.st-flex-grow-1 {
  flex-grow: 1 !important;
}

.st-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.st-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.st-flex-wrap {
  flex-wrap: wrap !important;
}

.st-flex-nowrap {
  flex-wrap: nowrap !important;
}

.st-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.st-gap-0 {
  gap: 0 !important;
}

.st-gap-1 {
  gap: 0.25rem !important;
}

.st-gap-2 {
  gap: 0.5rem !important;
}

.st-gap-3 {
  gap: 1rem !important;
}

.st-gap-4 {
  gap: 1.5rem !important;
}

.st-gap-5 {
  gap: 3rem !important;
}

.st-justify-content-start {
  justify-content: flex-start !important;
}

.st-justify-content-end {
  justify-content: flex-end !important;
}

.st-justify-content-center {
  justify-content: center !important;
}

.st-justify-content-between {
  justify-content: space-between !important;
}

.st-justify-content-around {
  justify-content: space-around !important;
}

.st-justify-content-evenly {
  justify-content: space-evenly !important;
}

.st-align-items-start {
  align-items: flex-start !important;
}

.st-align-items-end {
  align-items: flex-end !important;
}

.st-align-items-center {
  align-items: center !important;
}

.st-align-items-baseline {
  align-items: baseline !important;
}

.st-align-items-stretch {
  align-items: stretch !important;
}

.st-align-content-start {
  align-content: flex-start !important;
}

.st-align-content-end {
  align-content: flex-end !important;
}

.st-align-content-center {
  align-content: center !important;
}

.st-align-content-between {
  align-content: space-between !important;
}

.st-align-content-around {
  align-content: space-around !important;
}

.st-align-content-stretch {
  align-content: stretch !important;
}

.st-align-self-auto {
  align-self: auto !important;
}

.st-align-self-start {
  align-self: flex-start !important;
}

.st-align-self-end {
  align-self: flex-end !important;
}

.st-align-self-center {
  align-self: center !important;
}

.st-align-self-baseline {
  align-self: baseline !important;
}

.st-align-self-stretch {
  align-self: stretch !important;
}

.st-m-0 {
  margin: 0 !important;
}

.st-m-1 {
  margin: 0.25rem !important;
}

.st-m-2 {
  margin: 0.5rem !important;
}

.st-m-3 {
  margin: 1rem !important;
}

.st-m-4 {
  margin: 1.5rem !important;
}

.st-m-5 {
  margin: 3rem !important;
}

.st-m-auto {
  margin: auto !important;
}

.st-mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.st-mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.st-mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.st-mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.st-mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.st-mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.st-mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.st-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.st-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.st-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.st-my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.st-my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.st-my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.st-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.st-mt-0 {
  margin-top: 0 !important;
}

.st-mt-1 {
  margin-top: 0.25rem !important;
}

.st-mt-2 {
  margin-top: 0.5rem !important;
}

.st-mt-3 {
  margin-top: 1rem !important;
}

.st-mt-4 {
  margin-top: 1.5rem !important;
}

.st-mt-5 {
  margin-top: 3rem !important;
}

.st-mt-auto {
  margin-top: auto !important;
}

.st-mr-0 {
  margin-right: 0 !important;
}

.st-mr-1 {
  margin-right: 0.25rem !important;
}

.st-mr-2 {
  margin-right: 0.5rem !important;
}

.st-mr-3 {
  margin-right: 1rem !important;
}

.st-mr-4 {
  margin-right: 1.5rem !important;
}

.st-mr-5 {
  margin-right: 3rem !important;
}

.st-mr-auto {
  margin-right: auto !important;
}

.st-mb-0 {
  margin-bottom: 0 !important;
}

.st-mb-1 {
  margin-bottom: 0.25rem !important;
}

.st-mb-2 {
  margin-bottom: 0.5rem !important;
}

.st-mb-3 {
  margin-bottom: 1rem !important;
}

.st-mb-4 {
  margin-bottom: 1.5rem !important;
}

.st-mb-5 {
  margin-bottom: 3rem !important;
}

.st-mb-auto {
  margin-bottom: auto !important;
}

.st-ml-0 {
  margin-left: 0 !important;
}

.st-ml-1 {
  margin-left: 0.25rem !important;
}

.st-ml-2 {
  margin-left: 0.5rem !important;
}

.st-ml-3 {
  margin-left: 1rem !important;
}

.st-ml-4 {
  margin-left: 1.5rem !important;
}

.st-ml-5 {
  margin-left: 3rem !important;
}

.st-ml-auto {
  margin-left: auto !important;
}

.st-p-0 {
  padding: 0 !important;
}

.st-p-1 {
  padding: 0.25rem !important;
}

.st-p-2 {
  padding: 0.5rem !important;
}

.st-p-3 {
  padding: 1rem !important;
}

.st-p-4 {
  padding: 1.5rem !important;
}

.st-p-5 {
  padding: 3rem !important;
}

.st-px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.st-px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.st-px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.st-px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.st-px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.st-px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.st-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.st-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.st-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.st-py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.st-py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.st-py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.st-pt-0 {
  padding-top: 0 !important;
}

.st-pt-1 {
  padding-top: 0.25rem !important;
}

.st-pt-2 {
  padding-top: 0.5rem !important;
}

.st-pt-3 {
  padding-top: 1rem !important;
}

.st-pt-4 {
  padding-top: 1.5rem !important;
}

.st-pt-5 {
  padding-top: 3rem !important;
}

.st-pr-0 {
  padding-right: 0 !important;
}

.st-pr-1 {
  padding-right: 0.25rem !important;
}

.st-pr-2 {
  padding-right: 0.5rem !important;
}

.st-pr-3 {
  padding-right: 1rem !important;
}

.st-pr-4 {
  padding-right: 1.5rem !important;
}

.st-pr-5 {
  padding-right: 3rem !important;
}

.st-pb-0 {
  padding-bottom: 0 !important;
}

.st-pb-1 {
  padding-bottom: 0.25rem !important;
}

.st-pb-2 {
  padding-bottom: 0.5rem !important;
}

.st-pb-3 {
  padding-bottom: 1rem !important;
}

.st-pb-4 {
  padding-bottom: 1.5rem !important;
}

.st-pb-5 {
  padding-bottom: 3rem !important;
}

.st-pl-0 {
  padding-left: 0 !important;
}

.st-pl-1 {
  padding-left: 0.25rem !important;
}

.st-pl-2 {
  padding-left: 0.5rem !important;
}

.st-pl-3 {
  padding-left: 1rem !important;
}

.st-pl-4 {
  padding-left: 1.5rem !important;
}

.st-pl-5 {
  padding-left: 3rem !important;
}

.st-align-baseline {
  vertical-align: baseline !important;
}

.st-align-top {
  vertical-align: top !important;
}

.st-align-middle {
  vertical-align: middle !important;
}

.st-align-bottom {
  vertical-align: bottom !important;
}

.st-align-text-bottom {
  vertical-align: text-bottom !important;
}

.st-align-text-top {
  vertical-align: text-top !important;
}

.st-float-start {
  float: left !important;
}

.st-float-end {
  float: right !important;
}

.st-float-none {
  float: none !important;
}

.st-overflow-auto {
  overflow: auto !important;
}

.st-overflow-hidden {
  overflow: hidden !important;
}

.st-overflow-visible {
  overflow: visible !important;
}

.st-overflow-scroll {
  overflow: scroll !important;
}

.st-fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.st-fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.st-fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.st-fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.st-fs-5 {
  font-size: 1.25rem !important;
}

.st-fs-6 {
  font-size: 1rem !important;
}

.st-fs-7 {
  font-size: 0.85rem !important;
}

.st-fs-8 {
  font-size: 0.5rem !important;
}

.st-fst-italic {
  font-style: italic !important;
}

.st-fst-normal {
  font-style: normal !important;
}

.st-fw-light {
  font-weight: 300 !important;
}

.st-fw-lighter {
  font-weight: lighter !important;
}

.st-fw-normal {
  font-weight: 400 !important;
}

.st-fw-bold {
  font-weight: 700 !important;
}

.st-fw-bolder {
  font-weight: bolder !important;
}

.st-text-decoration-none {
  text-decoration: none !important;
}

.st-text-lowercase {
  text-transform: lowercase !important;
}

.st-text-uppercase {
  text-transform: uppercase !important;
}

.st-text-capitalize {
  text-transform: capitalize !important;
}

.st-text-start {
  text-align: left !important;
}

.st-text-end {
  text-align: right !important;
}

.st-text-center {
  text-align: center !important;
}

.st-rounded-0 {
  border-radius: 0 !important;
}

.st-rounded-1 {
  border-radius: 0.25rem !important;
}

.st-rounded-2 {
  border-radius: 0.5rem !important;
}

.st-rounded {
  border-radius: 1rem !important;
}

.st-rounded-circle {
  border-radius: 50% !important;
}

.st-rounded-full {
  border-radius: 9999px;
}

.st-rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.st-rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.st-rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.st-rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.st-border-thin {
  border-width: thin;
}

.st-border-medium {
  border-width: medium;
}

.st-border-thick {
  border-width: thick;
}

.st-border-solid {
  border-style: solid;
}

.st-border-dashed {
  border-style: dashed;
}

.st-visible {
  visibility: visible !important;
}

.st-invisible {
  visibility: hidden !important;
}

.st-cursor-pointer {
  cursor: pointer !important;
}

.st-cursor-text {
  cursor: text !important;
}

.st-cursor-help {
  cursor: help !important;
}

.st-cursor-default {
  cursor: default !important;
}

.st-bg__none {
  background: none !important;
}

.st-fill-current {
  fill: currentColor;
}
