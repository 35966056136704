.st-list {
  display: flex !important;
  flex-direction: column;
  justify-items: center;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 0 16px;
}

.st-card-list {
  display: block;
  overflow: auto;
  width: 100%;
}

.st-card-list {
  min-width: max-content;
  max-width: max-content;
}

.st-card-list {
  display: flex !important;
  flex-direction: column;
  position: relative;
  row-gap: 5px;
  min-width: 100% !important;
  max-width: 100% !important;
  height: 100%;
  max-height: 100%;
}

/* Sendo ajustado com flexlayoutmodule */

.st-card-list__content {
  max-height: 100%;
  overflow: auto;
}

.st-card-list__header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.st-card-list__selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
}

.st-list__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}
