%heading {
  margin: 0 !important;
  font-family: $headings-font-family;
  font-style: $headings-font-style;
  font-weight: $headings-font-weight !important;
  line-height: $headings-line-height !important;
  color: $headings-color;
}

h1 {
  @extend %heading;
  font-size: calc(1.375rem + 1.5vw) !important;
}

@media (min-width: 1200px) {
  h1 {
    font-size: $h1-font-size !important;
  }
}

h2 {
  @extend %heading;
  font-size: calc(1.325rem + 0.9vw) !important;
}
@media (min-width: 1200px) {
  h2 {
    font-size: $h2-font-size !important;
  }
}

h3 {
  @extend %heading;
  font-size: calc(1.3rem + 0.6vw) !important;
}
@media (min-width: 1200px) {
  h3 {
    font-size: $h3-font-size !important;
  }
}

h4 {
  @extend %heading;
  font-size: calc(1.275rem + 0.3vw) !important;
}
@media (min-width: 1200px) {
  h4 {
    font-size: $h4-font-size !important;
  }
}

h5 {
  @extend %heading;
  font-size: $h5-font-size !important;
}

h6 {
  @extend %heading;
  font-size: $h6-font-size !important;
}
