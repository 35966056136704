::-webkit-scrollbar {
  overflow: visible;
  height: 10px;
  width: 10px;
  transition: height 0.2s;
  background-color: rgba(0, 0, 0, 0);
}

/* ::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.06);
} */

::-webkit-scrollbar-button {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-corner {
  background: 0 0;
}

.st-dark-theme ::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.6);
}

.st-light-theme ::-webkit-scrollbar-thumb {
  background-color: rgba(10, 0, 40, 0.2);
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background-clip: padding-box;
  min-height: 28px;
  padding: 100px 0 0;
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
  border-radius: 12px;
  transition: 0.3s;
}

::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 1px;
}
