.st-menu {
  min-height: auto !important;

  .mat-menu-item {
    line-height: 38px !important;
    height: 38px !important;
  }

  .mat-menu-content:not(:empty) {
    padding: 0 !important;
  }
}
