@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary: map.get($color-config, 'primary');
  $accent: map.get($color-config, 'accent');
  $warn: map.get($color-config, 'warn');

  $background-hover: #{map.get(map.get($color-config, 'background'), 'hover')};
  $background-base: #{map.get(map.get($color-config, 'background'), 'background')};
  $foreground: map.get($color-config, 'foreground');

  st-sidenav-item {
    .item {
      color: mat.get-color-from-palette($foreground, 'text');

      &:hover,
      &.active {
        padding: 0.5rem 1.5rem;
        background: mat.get-color-from-palette($primary);
      }
    }

    .subheading {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
